import type { ReactNode } from 'react'

import { Box, Button, ButtonSecondary, Flex, Heading, IconArrowLeft, Separator, Stack } from '@adverity/design-system'
import { StaticImage } from '@adverity/image'
import { getPathTo } from '@adverity/legacy-datatap-paths/get-path-to'
import { keys } from 'remeda'

const errorContent = {
    '401': {
        title: 'Authentication Required',
        content: "You don't have permission to access this page",
        Image: '/403.svg',
    },
    '403': {
        title: 'Not Authorized',
        content: "You don't have permission to access this area",
        Image: '/403.svg',
    },
    '404': {
        title: 'Not found',
        content: 'We could not find what you are looking for',
        Image: '/404.svg',
    },
    '410': {
        title: 'Not found',
        content: 'We could not find what you are looking for',
        Image: '/404.svg',
    },
    '500': {
        title: 'System Failure',
        content: 'Sorry, something went wrong on our end',
        Image: '/500.svg',
    },
    '503': {
        title: 'Service Unavailable',
        content: 'Sorry, something went wrong on our end',
        Image: '/500.svg',
    },
} as const

type SupportedErrorCodes = keyof typeof errorContent

const getErrorPageTitle = (errorCode: SupportedErrorCodes) => `${errorCode} | ${errorContent[errorCode].title}`

const errorCodes = keys(errorContent)

const DEFAULT_ERROR_CODE = '404'

const getErrorPageVariant = (errorCode?: string) => {
    if (errorCode && errorCodes.includes(errorCode as (typeof errorCodes)[number])) {
        return errorCode as (typeof errorCodes)[number]
    }

    return errorCode?.toString().startsWith('5') ? '500' : DEFAULT_ERROR_CODE
}

const textProps = { display: 'block', textAlign: 'center' } as const
const ErrorContent = ({ code, children }: { code: SupportedErrorCodes; children?: ReactNode }) => {
    const IllustrationImage = errorContent[code].Image

    const title = getErrorPageTitle(code)

    return (
        <Flex width="100%" height="100%" alignItems="center" justifyContent="center" padding="xx-small" flexGrow={1}>
            {/* <PageHead title={title} /> */}
            <Stack spacing="medium" alignItems="center" maxWidth={600}>
                <Box width="100%" maxWidth={300}>
                    <StaticImage src={IllustrationImage} alt={title} width={300} height={300} />
                </Box>
                <Box alignItems="center">
                    <Heading level={3} {...textProps} as="p">
                        {code}
                    </Heading>
                    <Stack spacing="medium" alignItems="center">
                        <Heading
                            level={1}
                            {...textProps}
                            as="h1"
                            marginTop="xx-small"
                            marginBottom="xx-small"
                            fontWeight="bold"
                        >
                            {errorContent[code].title}
                        </Heading>
                        <Heading level={3} {...textProps} as="p">
                            {errorContent[code].content}
                        </Heading>
                    </Stack>
                </Box>
                {children}
            </Stack>
        </Flex>
    )
}

export const ErrorPage = ({ errorCode }: { errorCode?: string }) => {
    const supportedErrorCode = getErrorPageVariant(errorCode)

    return (
        <ErrorContent code={supportedErrorCode}>
            <Separator />
            <Stack orientation="horizontal" justifyContent="center" spacing="small">
                <Button href={getPathTo.home()}>
                    <IconArrowLeft /> Go Back Home
                </Button>

                <ButtonSecondary
                    as="a"
                    href={`mailto:support@adverity.com?subject=Support%20%7C%20${errorCode}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Contact support
                </ButtonSecondary>
            </Stack>
        </ErrorContent>
    )
}
