
// This file is generated. Do not modify it manually.
// Run 'pnpm nx run image:generate-asset-paths' to regenerate this file.

export type StaticAsset = keyof typeof assetsMap;
export type RawStaticAsset = string & { __brand: 'RawStaticAsset' }
export const assetsMap = {
    "/data-shares/data-shares-update-share.svg": new URL("../assets/data-shares/data-shares-update-share.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/tableau.svg": new URL("../assets/data-shares/tableau.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/power_bi.svg": new URL("../assets/data-shares/power_bi.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/data-shares-filters.svg": new URL("../assets/data-shares/data-shares-filters.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/enable-disable-destinations.svg": new URL("../assets/data-shares/enable-disable-destinations.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/chat_gpt.svg": new URL("../assets/data-shares/chat_gpt.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/data-value-administration-no-access.svg": new URL("../assets/data-shares/data-value-administration-no-access.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/odata.png": new URL("../assets/data-shares/odata.png", import.meta.url).href as RawStaticAsset,
    "/data-shares/qlik.svg": new URL("../assets/data-shares/qlik.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/data-shares-o-data.svg": new URL("../assets/data-shares/data-shares-o-data.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/domo.svg": new URL("../assets/data-shares/domo.svg", import.meta.url).href as RawStaticAsset,
    "/data-shares/destination-created.png": new URL("../assets/data-shares/destination-created.png", import.meta.url).href as RawStaticAsset,
    "/data-shares/data-shares-info-chat-gpt.svg": new URL("../assets/data-shares/data-shares-info-chat-gpt.svg", import.meta.url).href as RawStaticAsset,
    "/500.svg": new URL("../assets/500.svg", import.meta.url).href as RawStaticAsset,
    "/404.svg": new URL("../assets/404.svg", import.meta.url).href as RawStaticAsset,
    "/login/teaser.svg": new URL("../assets/login/teaser.svg", import.meta.url).href as RawStaticAsset,
    "/datastream-overview/one-off.svg": new URL("../assets/datastream-overview/one-off.svg", import.meta.url).href as RawStaticAsset,
    "/datastream-overview/looker-studio-refresh.webp": new URL("../assets/datastream-overview/looker-studio-refresh.webp", import.meta.url).href as RawStaticAsset,
    "/403.svg": new URL("../assets/403.svg", import.meta.url).href as RawStaticAsset,
    "/logo.svg": new URL("../assets/logo.svg", import.meta.url).href as RawStaticAsset,
    "/workspace-overview/adverity-binary.svg": new URL("../assets/workspace-overview/adverity-binary.svg", import.meta.url).href as RawStaticAsset,
    "/bulk-name-changes/add-suffix.svg": new URL("../assets/bulk-name-changes/add-suffix.svg", import.meta.url).href as RawStaticAsset,
    "/bulk-name-changes/add-prefix.svg": new URL("../assets/bulk-name-changes/add-prefix.svg", import.meta.url).href as RawStaticAsset,
    "/bulk-name-changes/preview-arrow.svg": new URL("../assets/bulk-name-changes/preview-arrow.svg", import.meta.url).href as RawStaticAsset,
    "/bulk-name-changes/find-and-replace.svg": new URL("../assets/bulk-name-changes/find-and-replace.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/datastreams.svg": new URL("../assets/help-page/datastreams.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/fetch-ad-performance-data.webp": new URL("../assets/help-page/fetch-ad-performance-data.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/set-up-data-mapping.webp": new URL("../assets/help-page/set-up-data-mapping.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/notifications.svg": new URL("../assets/help-page/notifications.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/currency-conversion.webp": new URL("../assets/help-page/currency-conversion.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/set-up-authorizations.webp": new URL("../assets/help-page/set-up-authorizations.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/load-data.webp": new URL("../assets/help-page/load-data.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/data-mapping.svg": new URL("../assets/help-page/data-mapping.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/data-shares.svg": new URL("../assets/help-page/data-shares.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/scheduling.svg": new URL("../assets/help-page/scheduling.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/data-explorer.svg": new URL("../assets/help-page/data-explorer.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/enrichment-copilot.svg": new URL("../assets/help-page/enrichment-copilot.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/authorizations.svg": new URL("../assets/help-page/authorizations.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/adverity-data-storage.svg": new URL("../assets/help-page/adverity-data-storage.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/enrichments.svg": new URL("../assets/help-page/enrichments.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/schedule-fetches.webp": new URL("../assets/help-page/schedule-fetches.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/transfer-data.webp": new URL("../assets/help-page/transfer-data.webp", import.meta.url).href as RawStaticAsset,
    "/help-page/data-dictionary.svg": new URL("../assets/help-page/data-dictionary.svg", import.meta.url).href as RawStaticAsset,
    "/help-page/create-datastream.webp": new URL("../assets/help-page/create-datastream.webp", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-addlink.svg": new URL("../assets/django-admin/icon-addlink.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-yes.svg": new URL("../assets/django-admin/icon-yes.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-unknown.svg": new URL("../assets/django-admin/icon-unknown.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/calendar-icons.svg": new URL("../assets/django-admin/calendar-icons.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-alert.svg": new URL("../assets/django-admin/icon-alert.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/selector-icons.svg": new URL("../assets/django-admin/selector-icons.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/search.svg": new URL("../assets/django-admin/search.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-no.svg": new URL("../assets/django-admin/icon-no.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-clock.svg": new URL("../assets/django-admin/icon-clock.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-unknown-alt.svg": new URL("../assets/django-admin/icon-unknown-alt.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-deletelink.svg": new URL("../assets/django-admin/icon-deletelink.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/inline-delete copy.svg": new URL("../assets/django-admin/inline-delete copy.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/inline-delete.svg": new URL("../assets/django-admin/inline-delete.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/chevron-down.svg": new URL("../assets/django-admin/chevron-down.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-calendar.svg": new URL("../assets/django-admin/icon-calendar.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/icon-changelink.svg": new URL("../assets/django-admin/icon-changelink.svg", import.meta.url).href as RawStaticAsset,
    "/django-admin/sorting-icons.svg": new URL("../assets/django-admin/sorting-icons.svg", import.meta.url).href as RawStaticAsset,
    "/google-data-studio/gds_instructions.png": new URL("../assets/google-data-studio/gds_instructions.png", import.meta.url).href as RawStaticAsset,
    "/google-data-studio/gds_browser_example.png": new URL("../assets/google-data-studio/gds_browser_example.png", import.meta.url).href as RawStaticAsset,
    "/google-data-studio/looker_studio_connect_tooltip.png": new URL("../assets/google-data-studio/looker_studio_connect_tooltip.png", import.meta.url).href as RawStaticAsset,
    "/google-data-studio/looker_studio_open_and_connect.gif": new URL("../assets/google-data-studio/looker_studio_open_and_connect.gif", import.meta.url).href as RawStaticAsset,
    "/google-data-studio/looker_studio_finalize.png": new URL("../assets/google-data-studio/looker_studio_finalize.png", import.meta.url).href as RawStaticAsset,
    "/google-data-studio/looker_studio_fields.svg": new URL("../assets/google-data-studio/looker_studio_fields.svg", import.meta.url).href as RawStaticAsset,
    "/data-overwrites/explanation@2x.png": new URL("../assets/data-overwrites/explanation@2x.png", import.meta.url).href as RawStaticAsset,
    "/empty.svg": new URL("../assets/empty.svg", import.meta.url).href as RawStaticAsset,
    "/image-dimension/same-width.svg": new URL("../assets/image-dimension/same-width.svg", import.meta.url).href as RawStaticAsset,
    "/image-dimension/same-height-cropped.svg": new URL("../assets/image-dimension/same-height-cropped.svg", import.meta.url).href as RawStaticAsset,
    "/image-dimension/same-height-left-aligned.svg": new URL("../assets/image-dimension/same-height-left-aligned.svg", import.meta.url).href as RawStaticAsset,
    "/enrichments/translation@2x.webp": new URL("../assets/enrichments/translation@2x.webp", import.meta.url).href as RawStaticAsset,
    "/enrichments/smart-naming-convention@2x.webp": new URL("../assets/enrichments/smart-naming-convention@2x.webp", import.meta.url).href as RawStaticAsset,
    "/enrichments/currency-conversion@2x.webp": new URL("../assets/enrichments/currency-conversion@2x.webp", import.meta.url).href as RawStaticAsset,
    "/enrichments/create-or-edit-columns@2x.webp": new URL("../assets/enrichments/create-or-edit-columns@2x.webp", import.meta.url).href as RawStaticAsset,
    "/enrichments/join@2x.png": new URL("../assets/enrichments/join@2x.png", import.meta.url).href as RawStaticAsset,
    "/enrichments/assistant-illustration.svg": new URL("../assets/enrichments/assistant-illustration.svg", import.meta.url).href as RawStaticAsset,
    "/enrichments/geo@2x.webp": new URL("../assets/enrichments/geo@2x.webp", import.meta.url).href as RawStaticAsset,
    "/enrichments/custom-script.png": new URL("../assets/enrichments/custom-script.png", import.meta.url).href as RawStaticAsset,
    "/enrichments/map@2x.webp": new URL("../assets/enrichments/map@2x.webp", import.meta.url).href as RawStaticAsset,
    "/schema-mapping/example.svg": new URL("../assets/schema-mapping/example.svg", import.meta.url).href as RawStaticAsset,
    "/onboarding/marketing-reporting-onboarding.png": new URL("../assets/onboarding/marketing-reporting-onboarding.png", import.meta.url).href as RawStaticAsset,
    "/data-quality/Adverity-2023-Illustration_FistBump.svg": new URL("../assets/data-quality/Adverity-2023-Illustration_FistBump.svg", import.meta.url).href as RawStaticAsset,
    "/data-quality/data-quality-ui-mock.png": new URL("../assets/data-quality/data-quality-ui-mock.png", import.meta.url).href as RawStaticAsset,
    "/data-quality/notification-dialog-illustration.png": new URL("../assets/data-quality/notification-dialog-illustration.png", import.meta.url).href as RawStaticAsset,
    "/data-quality/logo-adverity-seagreen-blurred-bg.png": new URL("../assets/data-quality/logo-adverity-seagreen-blurred-bg.png", import.meta.url).href as RawStaticAsset,
    "/authorization/testshib.png": new URL("../assets/authorization/testshib.png", import.meta.url).href as RawStaticAsset,
    "/authorization/google.svg": new URL("../assets/authorization/google.svg", import.meta.url).href as RawStaticAsset,
    "/authorization/onelogin.png": new URL("../assets/authorization/onelogin.png", import.meta.url).href as RawStaticAsset,
    "/authorization/github.jpg": new URL("../assets/authorization/github.jpg", import.meta.url).href as RawStaticAsset,
    "/connector/yaml.svg": new URL("../assets/connector/yaml.svg", import.meta.url).href as RawStaticAsset,
    "/super-lane/empty.svg": new URL("../assets/super-lane/empty.svg", import.meta.url).href as RawStaticAsset,
    "/adverity-storage/data-explorer-and-dashboards.svg": new URL("../assets/adverity-storage/data-explorer-and-dashboards.svg", import.meta.url).href as RawStaticAsset,
    "/adverity-storage/data-shares.svg": new URL("../assets/adverity-storage/data-shares.svg", import.meta.url).href as RawStaticAsset,
    "/adverity-storage/truncate.svg": new URL("../assets/adverity-storage/truncate.svg", import.meta.url).href as RawStaticAsset,
    "/adverity-storage/overwrite.svg": new URL("../assets/adverity-storage/overwrite.svg", import.meta.url).href as RawStaticAsset,
    "/adverity-storage/append.svg": new URL("../assets/adverity-storage/append.svg", import.meta.url).href as RawStaticAsset,
    "/adverity-storage/storage.svg": new URL("../assets/adverity-storage/storage.svg", import.meta.url).href as RawStaticAsset,
    "/datastreams/curved-right-arrow.svg": new URL("../assets/datastreams/curved-right-arrow.svg", import.meta.url).href as RawStaticAsset,
    "/datastreams/long-arrow-right.svg": new URL("../assets/datastreams/long-arrow-right.svg", import.meta.url).href as RawStaticAsset
};
